
<template>
  <div class="content-wrap" style="background: #fff">
    <div class="wrap">
      <div class="logo mb-48">
        <img class="logo_img" src="@/assets/image/medal_logo_grey.png" />
      </div>
      <div class="content">
        <input
          type="text"
          placeholder="이메일 아이디"
          class="login_input mt-32 mb-16 fr-14 p-16"
          v-model="email"
          @keypress.enter="doLogin()"
        />
        <input
          type="password"
          placeholder="비밀번호"
          class="login_input mb-16 fr-14 p-16"
          v-model="pass"
          @keypress.enter="doLogin()"
        />
        <span class="fm-12" style="color: red" v-if="isFail"
          >아이디 비밀번호를 확인해주세요.</span
        >

        <button-common
          :disabled="checkValid"
          class="finish mt-32"
          :size="SIZE_S"
          :clr="CLR_M"
          @click="doLogin()"
          >로그인</button-common
        >
      </div>
    </div>
    <footer>
      <div class="flex">
        <div style="margin-right: 125px">
          <img class="logo_footer" src="@/assets/image/medal_logo_grey.png" />
          <p class="footer_copyright">
            Copyright © Medal Corp. All rights reserved.
          </p>
        </div>
        <div>
          <p class="footer_content" style="font-weight: 700">고객센터</p>
          <div class="flex-align-center" style="margin-top: 8px">
            <img class="footer_ch" src="@/assets/image/footer_ch.png" />
            <span
              class="footer_content"
              style="cursor: pointer; text-decoration: underline"
              @click="clickChannel"
              >매달 파트너 고객센터</span
            >
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      email: "",
      pass: "",
      isFail: false,
    };
  },
  computed: {
    ...mapGetters(["G_NAV_ALL"]),
    checkValid() {
      return !this.email || !this.pass;
    },
  },
  mounted() {
    localStorage.removeItem("auth");
    localStorage.removeItem("authList");
    if (this.getBrowserInfo() === 'Safari') alert('현재 사파리 웹 브라우저에서는 이용이 어렵습니다.\n크롬 등 다른 웹 브라우저를 이용해주세요.\n이용문의는 ‘매달 파트너 카카오채널’로 남겨주시기 바랍니다.')
  },
  methods: {
    async doLogin() {
      const rs = await this.$axios({
        url: "/admin/account/login",
        params: { id: this.email, pass: this.pass },
      });
      if (rs.result === "SUC") {
        await this.getAuthList();
      } else {
        this.isFail = true;
      }
    },

    async getAuthList() {
      const rs = await this.$axios({
        url: "/admin/account/auth",
        params: {},
      });
      let authList = [];
      if (rs.adminAuth.comp.length > 0) {
        authList = [...authList, ...rs.adminAuth.comp.split(",")];
      }
      if (rs.adminAuth.member.length > 0) {
        authList = [...authList, ...rs.adminAuth.member.split(",")];
      }
      if (rs.adminAuth.pay.length > 0) {
        authList = [...authList, ...rs.adminAuth.pay.split(",")];
      }
      if (rs.adminAuth.alarm.length > 0) {
        authList = [...authList, ...rs.adminAuth.alarm.split(",")];
      }
      if (rs.adminAuth.notice.length > 0) {
        authList = [...authList, ...rs.adminAuth.notice.split(",")];
      }
      if (rs.adminAuth.system.length > 0) {
        authList = [...authList, ...rs.adminAuth.system.split(",")];
      }
      localStorage.setItem("auth", "ADM");
      localStorage.setItem("authList", authList.join(","));
      if (authList.length > 0) {
        this.goNextPage(authList[0]);
      } else {
        alert("권한이 모두 없습니다");
      }
    },

    goNextPage(auth) {
      let path = "";
      this.G_NAV_ALL.forEach((item) => {
        item.sub.forEach((data) => {
          if (data.auth === auth) {
            path = data.path;
          }
        });
      });
      this.$router.push({
        path: path,
      });
    },

    clickChannel() {
      window.open("https://www.instagram.com/medal.pay");
    },

    getBrowserInfo() {
      const agent = window.navigator.userAgent.toLowerCase();
      if (agent.indexOf('edge') >= 0) {
        return 'MS Edge';
      } else if (agent.indexOf('edg/') >= 0) {
        return 'Edge (chromium based)';
      } else if (agent.indexOf('opr') >= 0) {
        return 'Opera'
      } else if (agent.indexOf('chrome') >= 0) {
        return 'Chrome'
      } else if (agent.indexOf('trident') >= 0) {
        return 'MS IE'
      } else if (agent.indexOf('firefox') >= 0) {
        return 'Mozilla Firefox'
      } else if (agent.indexOf('safari') >= 0) {
        return "Safari"
      }
    }
  },
};
</script>

<style scoped lang="scss">
.wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.logo {
  text-align: center;
  height: 56px;
  line-height: 56px;
  margin-top: 100px;
}
.logo_img {
  width: 100px;
  height: 18px;
}
.logo_footer {
  width: 68px;
  height: 12px;
}
.content {
  width: 544px;
  height: 538px;
  padding: 32px;
  margin: 0 auto;
  background: var(--gray0);
  margin-bottom: 171px;
}
.content * {
  border: none;
}
.content .fm-12 {
  color: var(--gray50);
}

.checkbox-wrap {
  width: 100%;
  position: relative;
}
.checkbox-wrap span {
  color: var(--gray50);
}

footer {
  width: 100%;
  padding-top: 40px;
  padding-bottom: 16px;
  display: flex;
  justify-content: center;
  background: #f1f2f8;
  position: absolute;
  left: 0;
  bottom: 0;
}
.footer_copyright {
  font-weight: 500;
  font-size: 13px;
  color: #828699;
}
.footer_content {
  font-size: 13px;
  color: #828282;
  margin-top: 4px;
}
.footer_ch {
  width: 16px;
  height: 17px;
  margin-right: 2px;
}
.login_input {
  width: 100%;
  border: 1px solid var(--gray20);
  border-radius: 2px;
}
.finish {
  width: 480px !important;
  height: 48px !important;
}
button {
  background: var(--gray50);
  height: 48px;
  color: var(--white);
}
.find_IdPassword {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.find_IdPassword > * {
  padding: 8px;
  color: var(--gray70);
}
.find_IdPassword span {
  cursor: pointer;
  color: var(--gray50);
}
.find_IdPassword span:hover {
  color: var(--gray80);
}
input[type="text"] {
  width: 100%;
  padding: 16px;
  height: 56px;
  font-size: 14px;
  background: var(--white);
  color: var(--gray50);
  vertical-align: middle;
}
input[type="text"]:focus {
  background: var(--white);
  border: 1px solid var(--gray20);
  outline: none;
}
input[type="password"]:focus {
  outline: none;
}
</style>
